import { Component } from '@angular/core';
import { Platform, MenuController } from '@ionic/angular';
import { AlertControllerService } from './services/alert-controller.service';
import { NavigationService } from './services/navigation.service';
import { StorageService } from './services/storage.service';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import OneSignal from 'onesignal-cordova-plugin';
import { Router } from '@angular/router';
import { getMessaging, getToken } from "firebase/messaging";
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { initializeApp } from "firebase/app";
import { environment } from 'src/environments/environment';
import { ApiService } from './services/api.service';
import { Network, ConnectionStatus } from '@capacitor/network';
import { CommonService } from './services/common/common.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  deviceToken: any;
  isPWAInstalled = false;
  deferredPrompt: any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private alertControllerService: AlertControllerService,
    private storageService: StorageService,
    private naviGation: NavigationService,
    private menu: MenuController,
    private statusBar: StatusBar,
    private router: Router,
    private fcm: FCM,
    public apiService:ApiService,
    public commonService:CommonService,

  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    Network.addListener('networkStatusChange', (status) => {
      this.handleNetworkStatus(status);
  });


    // Code to get the web token to send notification using firebase 
    // OAdLOqQzhhRpVCCy4qX24f0J5N0jYR3HZt_8Ol78Vbw

    
      const messaging = getMessaging();
      console.log({messaging});

      getToken(messaging, { vapidKey: 'BA8u11L4QNH4gymQV1YEujv0CiQN2Fk0C2egKSJvncJMhSEo5U3bGZ0KmK5-serREnsqSXELmxU2Y-DCBrsi3M8' }).then((currentToken) => {
        if (currentToken) {
          console.log("currentToken>>>>>>>>>>>>>>>>>>>>>>>", currentToken);
        } else {
          (messaging as any).requestPermission().then(() => {
            console.log('Notification permission granted.');
            getToken(messaging, { vapidKey: 'BA8u11L4QNH4gymQV1YEujv0CiQN2Fk0C2egKSJvncJMhSEo5U3bGZ0KmK5-serREnsqSXELmxU2Y-DCBrsi3M8' }).then((newToken) => {
              if (newToken) {
                console.log("currentToken", newToken);
              }
            });
          })
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });


      let nativeDevice = await this.apiService.getCurrentDevice();
      console.log('native ==',nativeDevice);

      if(nativeDevice){   

        this.fcm.getToken().then((token: string) => {
   
          console.log('FCM Token:>>>>>>>>>>>>>>>', token);
        }).catch((error: any) => {
          console.error('Error getting FCM token:>>>>>>>>>>', error);
        });
    
        this.fcm.onNotification().subscribe((data: any) => {
          // Handle the received push notification
          console.log('Received push notification:', data);
        });

      }

      
  }


  async checkNetworkStatus() {
    const status = await Network.getStatus();
    this.handleNetworkStatus(status);
}

handleNetworkStatus(status: ConnectionStatus) {
    if (status.connected) {
        console.log('Internet connected !!');
      //  this.commonService.presentToastNet('Internet connected !!', 'success');
    } else {
        console.log('No internet connection');
       // this.commonService.presentToastNet('No internet connection !!');
    }
}

  async requestPushNotificationsPermission() {

    // Code to ask permission for the IOS devices to open permission promp when the app is installed
    if(this.platform.is('cordova')){

    const wasPermissionGiven: boolean = await this.fcm.requestPushPermission({

      ios9Support: {
        timeout: 10,  // How long it will wait for a decision from the user before returning `false`
        interval: 0.3 // How long between each permission verification
      },

    })

    console.log("wasPermissionGiven", wasPermissionGiven);

    // Code to get the device token to send notification using firebase 


    this.fcm.getToken().then((res: any) => {
      console.log("response>>>", res)
    });

    this.fcm.onNotification().subscribe(data => {
      console.log("data", data);
      if (data.wasTapped) {
        console.log("Received in background");
      } else {
        console.log("Received in foreground");
      };
    });

    this.fcm.onTokenRefresh().subscribe(token => {
      console.log("token******", token);
    });

    this.fcm.hasPermission().then(hasPermission => {
      if (hasPermission) {
        console.log("Has permission!");
      }
    })
  }

  }

  initializeApp() {
    this.platform.ready().then(async () => {
      const app = initializeApp(environment.firebaseConfig);
      //  this.OneSignalInit()
      if (this.platform.is("cordova") && this.apiService.isPlatformAndroidIos) {
        await this.requestPushNotificationsPermission();
      }

      this.platform.backButton.subscribeWithPriority(9999, () => {
        document.addEventListener(
          'backbutton',
          function (event) {
            event.preventDefault();
            event.stopPropagation();
          },
          false
        );
      });

      const isPWAInstalledOnDesktop: boolean = document.getElementsByClassName('plt-pwa').length > 0;
      if (!isPWAInstalledOnDesktop && this.platform.is('desktop')) {
        console.log('pwa url');
      } else {
        if (this.platform.is('cordova') && this.apiService.isPlatformAndroidIos) {
          // this.OneSignalInit();
          this.requestPushNotificationsPermission();
        }
      
      }


      this.statusBar.styleDefault();
      this.statusBar.backgroundColorByHexString('#cfe7f7');
      this.splashScreen.hide();
      this.redirectUser();
    });
  }

  async redirectUser() {
    this.storageService.getFromNativeStorage('user:Lullabuy').then((user) => {
      if (user != null) {
        this.naviGation.goToRoot('/');
      } else {
        this.naviGation.goToRoot('/login');
      }
    });
  }

  logout() {
    const alertHeader = 'Logout';
    const alertMessage = 'Are you sure ?';
    this.alertControllerService
      .presentAlertWithHeader(alertHeader, alertMessage)
      .then((userResponse) => {
        if (userResponse) {
          this.storageService.clearNativeStorage();
          this.menu.enable(false);
          this.naviGation.setRoot('/login');
        }
      });
  }


  // OneSignalInit() {
  //   var self = this;
  //   console.log("OneSignalInit");
  //   OneSignal.setAppId("bc6a7e65-22a9-45bf-a9f6-9baa6713dbe5");
  //   OneSignal.setNotificationOpenedHandler(function (jsonData) {
  //     console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
  //     console.log(jsonData['notification']['additionalData']['type']);
  //     console.log(jsonData['notification']['additionalData']['uuid']);
  //     self.router.navigate(['/notification']);
  //   });

  //   OneSignal.setNotificationWillShowInForegroundHandler(function (data) {
  //     console.log('Notification received');
  //   });

  //   OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
  //     console.log("User accepted notifications: " + accepted);
  //   });

  //   setTimeout(() => {
  //     OneSignal.getDeviceState(res => {
  //       console.log("res: ", res);
  //       let temp: any = res;
  //       this.deviceToken = temp.userId;
  //       console.log(this.deviceToken);
  //     });
  //   }, 2000);
  // }

  installPWA() {
    if (this.deferredPrompt) {
      // Trigger the installation prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // User accepted the prompt
          console.log('PWA installation accepted');
        } else {
          // User dismissed the prompt
          console.log('PWA installation dismissed');
        }
        // Reset the deferredPrompt
        this.deferredPrompt = null;
      });
    }
  }
}
