import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertControllerService {

  constructor(
    private alertController: AlertController,
    public loadingCtrl: LoadingController,
  ) { }


  presentAlert(alertMessage: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'artstock_alert_2',
        message: alertMessage,
        mode: 'ios',
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
            handler: () => resolve(false)
          }
        ]
      });

      await alert.present();
    });
  }

  presentAlertWithHeader(alertHeader: string, alertMessage: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'artstock_alert_2',
        header: alertHeader,
        message: alertMessage,
        mode: 'ios',
        buttons: [
          {
            text: 'Yes',
            handler: () => resolve(true)
          },
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => resolve(false)
          }
        ]
      });

      await alert.present();
    });
  }

  presentAlertWithButtons(alertMessage: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'artstock_alert_2',
        message: alertMessage,
        mode: 'ios',
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => resolve(true)
          }
        ]
      });

      await alert.present();
    });
  }

  showLoader(msg:any='') {
    if(msg==''){
      msg = "Loading..."; 
    }
    this.loadingCtrl.create({
      message:msg
    }).then((response) => {
      response.present();
    });
  }
  // Dismiss loader
  dismissLoader() {
    this.loadingCtrl.dismiss().then((response) => {
      console.log('Loader closed!', response);
    }).catch((err) => {
      console.log('Error occured : ', err);
    });
  }

  async presentEmailAlert() {
    const alert = await this.alertController.create({
      header: 'Email Input',
      inputs: [
        {
          name: 'email',
          type: 'email',
          placeholder: 'Enter your email'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'OK',
          handler: (data) => {
            console.log('Entered email: ', data.email);
            return  data.email;
            // You can perform further actions with the entered email here
          }
        }
      ]
    });
  
    await alert.present();
  }
  persentAlerWithConfirmation(alertHeader: string, alertMessage: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'artstock_alert_2',
        header: alertHeader,
        message: alertMessage,
        mode: 'ios',
        buttons: [
          {
            text: 'Yes',
            handler: () => resolve(true)
          },
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => resolve(false)
          }
        ]
      });

      await alert.present();
    });
  }
}
