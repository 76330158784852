import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map, retry } from 'rxjs/operators';
import { LoadingController, Platform } from '@ionic/angular';

// import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { BehaviorSubject, Subject } from 'rxjs';
// const mainUrl = 'https://ionic6b.betaplanets.com/api/';
// const apiV1 = 'https://ionic6b.betaplanets.com/api/';
const mainUrl = 'https://lullabuy.app/';
const apiV1 = 'https://lullabuy.app/wp-json/mobileapi/v1/';
const apiV2 = 'https://lullabuy.app/wp-json/wp/v2/';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  loading: any;
  userLoginSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  totalPosts = null;
  pages: any;
  isPlatformAndroidIos:boolean=false;

  constructor(
    private http: HttpClient,
    private platform: Platform,
    // private oneSignal: OneSignal,
    public loadingCtrl: LoadingController,
  ) { 

    const isapp: any = (document.URL.startsWith('https://app.lullabuy.app/') || document.URL.startsWith('http://app.lullabuy.app/') || document.URL.startsWith('http://localhost:8100'));
    if (isapp) {
        console.log("PWA");
        this.isPlatformAndroidIos = false;
        console.log("isPlatformAndroidIos=", this.isPlatformAndroidIos)
    } else {
        console.log("Device");
        this.isPlatformAndroidIos = true;
        console.log("isPlatformAndroidIos=", this.isPlatformAndroidIos)
    }

  }

  async getCurrentDevice(){
    const isapp: any = (document.URL.startsWith('https://app.lullabuy.app/') || document.URL.startsWith('http://app.lullabuy.app/') || document.URL.startsWith('http://localhost:8100'));
    if (isapp) {
        console.log("PWA");
        this.isPlatformAndroidIos = false;
        return false
        console.log("isPlatformAndroidIos=", this.isPlatformAndroidIos)
    } else {
        console.log("Device");
        this.isPlatformAndroidIos = true;
        return true;
        console.log("isPlatformAndroidIos=", this.isPlatformAndroidIos)
    }
  }

  getURL() {
    return mainUrl;
  }

  async dismissLoading() {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }

  async showLoader(msg: string = '') {
    if (msg === '') {
      msg = 'Please wait...';
    }
    this.loading = await this.loadingCtrl.create({ message: msg });
    await this.loading.present();
  }

  // getData(endPoint) {
  //   return this.http.get(apiV1 + endPoint).pipe(map((data) => data));
  // }

  // sendData(endPoint, data) {
  //   return this.http.post(apiV1 + endPoint, data).pipe(map((result) => result));
  // }


  doReset(email) {
    return this.http.post(mainUrl + 'forgotPassword', {
      email: email
    }).pipe(
      map(user => {
        return user;
      })
    )
  }
  getAbout() {
    return this.http.get(mainUrl + 'get_about_us');
  }

  // doLogin(endPoint, data) {
  //   return this.http
  //     .post(mainUrl + endPoint, data)
  //     .pipe(map((result) => result));

  // }

  // async SaveAutoConfiqure(token) {
  //   if (this.platform.is('cordova')) {
  //     // this.oneSignal.getIds().then((id) => {
  //     //   this.saveOneSignID(token, id.userId).subscribe((m) => {});
  //     // });
  //   }
  // }



  getData(endPoint) {
    return this.http.get(apiV1 + endPoint).pipe(
      map(data => data)
    );
  }

  callYoutubeApi(endPoint) {
    return this.http.get(endPoint).pipe(
      map(data => data)
    );
  }


  getYoutube() {
    var apiKey = 'AIzaSyAauHW1DV_QpYSlXC7t7jIu2_IrDGPqrAU';

    // Specify the channel ID you want to retrieve videos from
    var channelId = 'UCBR8-60-B28hp2BmDPdntcQ';

    // Set the number of results to retrieve
    var maxResults = 10;

    // Construct the API request URL
    var url = 'https://www.googleapis.com/youtube/v3/search';
    url += '?part=snippet';
    url += '&channelId=' + channelId;
    url += '&maxResults=' + maxResults;
    url += '&order=date';
    url += '&key=' + apiKey;

    return this.http.get(url).pipe(
      map(data => data)
    );
  }
  sendData(endPoint, data, maxRetries = 0) {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    data.timezone = timezone;
  
    return this.http.post(apiV1 + endPoint, data).pipe(
      retry(maxRetries),
      map(result => result)
    );
  }

  doLogin(endPoint, data) {
    return this.http.post(mainUrl + 'wp-json/jwt-auth/v1/' + endPoint, data).pipe(
      map(result => result)
    );
  }
  updateToken(userToken, deviceID, deviceData, status) {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.http.post(mainUrl + "wp-json/mobileapi/v1/updateDeviceToken", {
      token: userToken,
      deviceID: deviceID,
      deviceData: deviceData,
      status: status,
      timezone: timezone

    })
  }

  getNoti(token, page_no) {
    return this.http.post(mainUrl + "wp-json/mobileapi/v1/get_my_notifications", {
      token: token,
      page: page_no
    })
  }
  deleteNoti(nid) {
    return this.http.post(mainUrl + "wp-json/mobileapi/v1/deleteNoti", {
      nid: nid
    })
  }

  uploadFile(formData: any) {
    return this.http.post(mainUrl + 'wp-json/mobileapi/v1/update_images', formData);
  }

  getPageContent(page: number = 1) {
    //if we want to query posts by category
    return this.http.get(
      mainUrl +
      'wp-json/wp/v2/pages/' + page)
      .pipe(
        map(content => {
          return content;
        })
      )
  }



  getDownloadUrl(url) {

    const headers = new HttpHeaders({
      'content-type': 'application/json',
      'X-Forwarded-For': '70.41.3.18',
      'X-RapidAPI-Key': '9d24c214c0msh65c3b87992454e0p1157a8jsn6476842902d1',
      'X-RapidAPI-Host': 'youtube86.p.rapidapi.com'
    });
    

  //  2e0d5f240amsh63938f8f342cfffp1048fdjsn92f2710ba939
    return this.http.post("https://youtube86.p.rapidapi.com/api/youtube/links", url, { headers })
  }



}
