import { Injectable } from '@angular/core';
import { AlertController, LoadingController,ToastController, Platform, } from '@ionic/angular';
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as momenttimezone from 'moment-timezone';
import { Events } from './events.service';
import { environment } from '../../../environments/environment';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public loading: any;

  constructor(
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public events: Events,
    private router: Router,
    public platform: Platform,
    public toast: ToastController
  ) { }

  async showLoader(msg) {
    this.loading = await this.loadingCtrl.create({
      message:
      msg,
      cssClass: 'scale-down-center',
      translucent: true,
      showBackdrop: true,
      spinner: null,
    });
    this.loading.present();
  }


  async dismiss() {
    return await this.loadingCtrl.dismiss();
  }

  async presentAlertConfirmInValid(msg: any, page: any, navigation: any) {
    const alert = await this.alertCtrl.create({
      message:
        '<div class="round_class"><ion-img src="./assets/alerts/smiley.png" alt="loading..." id="alert_img"></ion-img></div>' +
        '<div class="mes_div"><span class="alert_mes">' +
        msg +
        '</span></div>',
      backdropDismiss: false,
      mode: 'ios',
      cssClass: 'alert_positive',
      buttons: [
        {
          text: 'Okay',
          handler: () => {
            this.router.navigate([page], navigation);
          },
        },
      ],
    });

    await alert.present();
    return false;
  }

  async presentAlertConfirmValid(msg: any, page: any, navigation: any) {
    const alert = await this.alertCtrl.create({
      message:
        '<div class="round_class"><ion-img src="./assets/alerts/sad.png" alt="loading..." id="alert_img"></ion-img></div>' +
        '<div class="mes_div"><span class="alert_mes">' +
        msg +
        '</span></div>',
      backdropDismiss: false,
      mode: 'ios',
      cssClass: 'alert_positive',
      buttons: [
        {
          text: 'Okay',
          handler: () => {
            this.router.navigate([page], navigation);
          },
        },
      ],
    });

    await alert.present();
    return false;
  }

  public async presentAlert(msg: any) {
    let alert = await this.alertCtrl.create({
      message:
        '<div class="round_class"><ion-img src="./assets/alerts/sad.png" alt="loading..." id="alert_img"></ion-img></div>' +
        '<div class="mes_div"><span class="alert_mes">' +
        msg +
        '</span></div>',
      backdropDismiss: false,
      mode: 'ios',
      cssClass: 'alert_negative',
      buttons: ['Okay'],
    });

    await alert.present();
  }
  public async presentPositiveAlert(msg: any) {
    let alert = await this.alertCtrl.create({
      message:
        '<div class="round_class"><ion-img src="./assets/alerts/smiley.png" alt="loading..." id="alert_img"></ion-img></div>' +
        '<div class="mes_div"><span class="alert_mes">' +
        msg +
        '</span></div>',
      backdropDismiss: false,
      mode: 'ios',
      cssClass: 'alert_positive',
      buttons: ['Okay'],
    });

    await alert.present();
  }

  public convertdatetime(date: any) {
    var sdate = moment(date, 'YYYY-MM-DDTHH:mm:ssTZD').format(
      'YYYY-MM-DD HH:mm:ss'
    );
    return sdate;
  }

  public convertdate(date: any) {
    var sdate = moment(date, 'YYYY-MM-DDTHH:mm:ssTZD').format('MM-DD-YYYY');
    return sdate;
  }
  public convertMeetingDate(date: any) {
    var sdate = moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');
    return sdate;
  }
  public convertExpDate(date: any) {
    var sdate = moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
    return sdate;
  }
  public getPickerTime(time: any) {
    var timeformat = moment(time, 'YYYY-MM-DDTHH:mmZ').format('HH:mm');
    return timeformat;
  }

  public convertPickerTime(time: any) {
    var timeformat = moment(time, 'HH:mm').format('YYYY-MM-DDTHH:mmZ');
    return timeformat;
  }

  public showMeetDate(date: any) {
    var sdate = moment(date, 'YYYY-MM-DD').format('MMM, DD YYYY');
    return sdate;
  }

  public showMeetTime(time: any) {
    var timeformat = moment(time, 'HH:mm').format('hh:mm A');
    return timeformat;
  }
  public convertshowdate(date: any) {
    var sdate = moment(date, 'YYYY-MM-DDTHH:mm:ssTZD').format('MM-DD-YYYY');
    return sdate;
  }
  public convertshowdatetime(date: any) {
    var sdate = moment(date, 'YYYY-MM-DDTHH:mm:ssTZD').format(
      'YYYY-MM-DD HH:mm:ss'
    );
    return sdate;
  }

  public timezone() {
    var tz = momenttimezone.tz.guess();
    return tz;
  }

  public get24hoursformat(time) {
    var timeformat = moment(time, 'hh:mm a').format('HH:mm:ss');
    return timeformat;
  }

  public get24hours(time) {
    var timeformat = moment(time, 'hh:mm a').format('HH:mm');
    return timeformat;
  }
  public get12hoursformat(time) {
    var timeformat = moment(time, 'HH:mm:ss').format('hh:mm a');
    return timeformat;
  }

  public HoursFormat(time) {
    var timeformat = moment(time).format('HH:mm:ss');
    return timeformat;
  }

  public getAMPMformat(time) {
    var timeformat = moment(time, 'YYYY-MM-DD HH:mm:ss').format('hh:mm a');
    return timeformat;
  }

  public getInvoiceDate(time) {
    var timeformat = moment(time, 'YYYY-MM-DD HH:mm:ss').format(
      'DD MMM YY @ hh:mm a'
    );
    return timeformat;
  }

  public getdiffencedate(start: any, end: any) {
    var a = moment(end, 'MMM YYYY');
    var b = moment(start, 'MMM YYYY');

    var years = a.diff(b, 'year');
    b.add(years, 'years');

    var months = a.diff(b, 'months');
    b.add(months, 'months');
    if (years != 0) {
      if (months != 0) {
        return years + ' yr ' + months + ' mo';
      } else {
        return years + ' yr';
      }
    } else {
      if (months != 0) {
        return months + ' mo';
      }
    }
  }
  public getdifferenceTime(start: any, end: any) {
    var TimeA = moment(start, 'HH:mm:ss');
    var TimeB = moment(end, 'HH:mm:ss');

    var duration = moment.duration(TimeB.diff(TimeA)).asMinutes();
    if (duration < 0) {
      duration = this.getdifferenceTime(end, start);
    }

    return duration;
  }
  convertToDay(minute) { }

  formatDate(date) {
    let d = new Date(date),
      day = '' + d.getDate(),
      month = '' + (d.getMonth() + 1),
      year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  formatTime(date) {
    let d = new Date(date),
      hour = '' + d.getHours(),
      minute = '' + d.getMinutes(),
      second = '' + d.getSeconds();
    if (hour.length < 2) {
      hour = '0' + hour;
    }
    if (second.length < 2) {
      second = '0' + second;
    }
    if (minute.length < 2) {
      minute = '0' + minute;
    }
    return [hour, minute, second].join(':');
  }

  public getSystemDateTime() {
    const date = new Date();
    var fulldate = this.formatDate(date);
    var fulltime = this.formatTime(date);
    var fulldt = fulldate + ' ' + fulltime;
    return fulldt;
  }

  public getsystemdate() {
    let fulldate: any;
    let tmn: any;
    let fulldt: any;
    var dt = new Date();
    let dy: any;
    let ss: any;
    let mn: any;
    var yr = dt.getFullYear();
    dy = dt.getDate();
    var tm = dt.getHours();
    var min = dt.getMinutes();
    ss = dt.getSeconds();
    if (dy < 10) {
      dy = '0' + dy;
    } else {
      dy = dy;
    }
    if (ss < 10) {
      ss = '0' + ss;
    } else {
      ss = ss;
    }
    mn = dt.getMonth() + 1;
    if (mn < 10) {
      mn = '0' + mn;
    } else {
      mn = tmn;
    }
    var fulltime = tm + ':' + min + ':' + ss;
    fulldate = yr + '-' + mn + '-' + dy;
    fulldt = fulldate;

    return fulldt;
  }

  public getTime(starttime, endtime) {
    if (starttime >= endtime) {
      return true;
    } else {
      return null;
    }
  }
  public getNewsDate(time) {
    var timeformat = moment(time, 'YYYY-MM-DD HH:mm:ss').format('DD MMM, YYYY');
    return timeformat;
  }
  public getOrderDate(time) {
    var timeformat = moment(time, 'YYYY-MM-DD HH:mm:ss').format(
      'MMMM DD , YYYY'
    );
    return timeformat;
  }
  public getPaymentDate(time) {
    var timeformat = moment(time, 'MM/YYYY').format('MMM YYYY');
    return timeformat;
  }
  public getWalletDate(time) {
    var timeformat = moment(time, 'YYYY-MM-DD HH:mm:ss').format(
      'MM-DD-YYYY hh:mm a'
    );
    return timeformat;
  }
  public getGmtTime(time) {
    var timeformat = moment(time, 'YYYY-MM-DD HH:mm:ss')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    return timeformat;
  }
  public getTimerDate(time) {
    var timeformat = moment(time, 'YYYY-MM-DD HH:mm:ss').format('MMM DD');
    return timeformat;
  }
  public timeConvert(data: number) {
    if (data > 59) {
      var minutes = data % 60;

      var hours = (data - minutes) / 60;

      return (
        hours +
        ' hours ' +
        parseFloat(minutes.toString()).toFixed(2) +
        ' minutes'
      );
    }
    if (data < 1) {
      var minutes = data;

      return minutes + ' seconds';
    } else {
      var minutes = data;

      return minutes + ' minutes';
    }
  }
  public getTimefromSys(time) {
    var timeformat = moment(time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss');
    return timeformat;
  }
  public getDatefromSys(time) {
    var timeformat = moment(time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
    return timeformat;
  }

  public console(msg: any, data: any) {
    if (environment.is_dev) {
    }
  }

  public isOnline() {
    if (!navigator.onLine) {
      this.presentAlert('Please check your internet connection.');
      return false;
    } else {
      setTimeout(() => {
        if (!navigator.onLine) {
          this.presentAlert('Please check your internet connection.');
          return false;
        }
      }, 2000);
      return true;
    }
  }

  public makeid(length) {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public convertObjDate(date: any) {
    var sdate = moment(date, 'MM/DD/YYYY').format('MM-DD-YYYY');
    return sdate;
  }

  public getFPrevDate(y: any, value: any) {
    var startdate = moment().subtract(y, value);
    return startdate.format("YYYY-MM-DD");
  }

  public getFCurrentDate() {
    var startdate = moment();
    return startdate.format("YYYY-MM-DD");
  }

  public getFirstDate() {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    return startOfMonth
  }

  public getLastDate() {
    const startOfMonth = moment().endOf('month').format('YYYY-MM-DD');
    return startOfMonth
  }

  public getConverted(v: any) {

    // Nine Zeroes for Billions
    return Math.abs(Number(v)) >= 1.0e+9

      ? (Math.abs(Number(v)) / 1.0e+9).toFixed(2) + "B"
      // Six Zeroes for Millions
      : Math.abs(Number(v)) >= 1.0e+6

        ? (Math.abs(Number(v)) / 1.0e+6).toFixed(2) + "M"
        // Three Zeroes for Thousands
        : Math.abs(Number(v)) >= 1.0e+3

          ? (Math.abs(Number(v)) / 1.0e+3).toFixed(2) + "K"

          : Math.abs(Number(v));

  }

  public getConvertTime(tS: any, type: any) {
    if (type == '1') {
      const formatted = moment(tS).utc().format('hh:mm A');
      return formatted;
      // } else if (type == '2') {
      //   const formatted = moment(tS).format("dddd");
      //   return formatted;
      // }
    } else if (type == '2') {
      const formatted = moment(tS).utc().format("Do MMM");
      return formatted;
    } else if (type == '3') {
      const formatted = moment(tS).utc().format("Do MMM");
      return formatted;
    } else if (type == '4' || type == '5' || type == '6') {
      const formatted = moment(tS).utc().format("MMM YYYY");
      return formatted;
    } else if (type == '7' || type == '8') {
      const formatted = moment(tS).utc().format("YYYY");
      return formatted;
    }
  }
  public nextMonth(day) {
    var futureMonth = moment(day, "YYYY-MM-DD HH:mm:ss")
      .add(1, "M")
      .format("YYYY-MM-DD HH:mm:ss");

    return futureMonth;
  }

  public getExpireDate(day) {
    var timeformat = moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM, YYYY hh:mm A');
    return timeformat;
  }

  public getConvertedDate(tS: any) {
    const formatted = moment(tS).utc().format('YYYY-MM-DD');
    return formatted;
  }

  public checkPrevDay(start: any, end: any) {

    var TimeA = moment(start, 'YYYY-MM-DD HH:mm:ss');
    var TimeB = moment(end, 'YYYY-MM-DD HH:mm:ss');
    if (TimeA.isAfter(TimeB)) {
      return true;
    } else {
      return false;
    }
    // return duration;
  }

  public utcToLocal(dT: any) {
    let local = moment.utc(dT).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  }

  public getDateOrToday(date) {
    // date = "2022-01-05 11:48:17"
    if (moment.utc(date).local().isSame(moment(), 'day')) {
      return moment.utc(date).local().fromNow();
    } else {
      return moment.utc(date).local().format('MMM DD/ YYYY');
    }
  }

  public getCalenderToday() {
    var timeformat = moment().format('YYYY-MM-DDTHH:mmZ');
    return timeformat;
  }

  public getCalenderFuture() {
    var startdate = moment().add(99, 'year');
    return startdate.format("YYYY-MM-DDTHH:mmZ");
  }

  public getMeetingLocal(time: any) {
    let local = moment.utc(time).local().format("hh:mm A");
    return local;
  }
  public getMeetingTitle(time: any) {
    let local = moment.utc(time).local().format("DD MMM, YYYY");
    return local;
  }
  public getAfter1HoursTime() {
    let Time = moment().add(1, 'hours').format('YYYY-MM-DDTHH:mmZ');
    return Time
  }

  public getAdd1HoursTime(date: any) {
    let Time = moment(date).add(1, 'hours').format('YYYY-MM-DD HH:mm');
    return Time
  }

  public get15MinBackTime(date: any) {
    let Time = moment(date).subtract(15, 'minutes').format('YYYY-MM-DD HH:mm');
    return Time
  }

  public convertObj(date: any) {
    var sdate = moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');
    return sdate;
  }
  public getCurrentDateTime() {
    var startdate = moment();
    return startdate.format("YYYY-MM-DD HH:mm");
  }

  public get1DayDate() {
    var startdate = moment();
    let day = moment(startdate).subtract(1, 'days').format('YYYY-MM-DD');
    return day
  }

  public getCurrentdatesDiff(day) {
    // let sday = moment.utc(day).local().diff(moment(), "hours"); // 0
    // return sday;
    var duration = moment.duration(moment.utc(day).local().diff(moment()));
    var diff = duration.asMinutes();
    return diff;
  }

  public parseData(data: any) {
    return JSON.stringify(data);
  }

  stringToHTML(str) {
    if (str != undefined) {
      let msg = '';
      // '&#x'
      let array_str = str.split(/&#x|;| /);
      if (array_str.length > 0) {
        array_str.forEach((element) => {
          if (element) {
            msg += this.convert_emoji(element) + ' ';
          } else {
            msg += ' ';
          }
        });
      }
      return msg;
    }
    return '';
  }

  convert_emoji(a) {
    let msg = a.replace(';', '');
    let check = Number(parseInt(msg));
    if (check != 1) {
      return a;
    } else {
      return String.fromCodePoint(parseInt(msg, 16));
    }
  }

  public convertFBLocal(timestamp) {
    return moment.unix(timestamp).fromNow();
  }

  async presentToast(msg) {
    const toast = await this.toast.create({
      message: msg,
      duration: 2000,
      position: 'bottom'
    });
    toast.present();
  }

  async dismissLoading() {
    await this.loading.dismiss();
  }

  async presentToastNet(msg: string, type?: string) {
        const toast = await this.toast.create({
            message: msg,
            duration: 2000,
            position: 'bottom',
            cssClass: (type == 'success') ? 'success-toast' : 'error-toast'
        });

        toast.present();
    }

}
