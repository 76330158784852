import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  
  {
    path: 'login',
    loadChildren: () => import('./beforeLoginPages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./beforeLoginPages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./beforeLoginPages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./beforeLoginPages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'spacial-code',
    loadChildren: () => import('./beforeLoginPages/spacial-code/spacial-code.module').then( m => m.SpacialCodePageModule)
  },
 
  {
    path: 'contact-us',
    loadChildren: () => import('./commonpages/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./commonpages/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
 
  {
    path: 'edit-profile',
    loadChildren: () => import('./commonpages/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./commonpages/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
 
  {
    path: 'send-song',
    loadChildren: () => import('./pages/send-song/send-song.module').then( m => m.SendSongPageModule)
  },
  {
    path: 'confirm-recipient',
    loadChildren: () => import('./pages/confirm-recipient/confirm-recipient.module').then( m => m.ConfirmRecipientPageModule)
  },

  {
    path: 'add-song',
    loadChildren: () => import('./pages/add-song/add-song.module').then( m => m.AddSongPageModule)
  },
  {
    path: 'confirm-song',
    loadChildren: () => import('./pages/confirm-song/confirm-song.module').then( m => m.ConfirmSongPageModule)
  },
  {
    path: 'trim-song',
    loadChildren: () => import('./pages/trim-song/trim-song.module').then( m => m.TrimSongPageModule)
  },
  {
    path: 'request-song',
    loadChildren: () => import('./pages/request-song/request-song.module').then( m => m.RequestSongPageModule)
  },
 
  {
    path: 'request-song-details',
    loadChildren: () => import('./pages/request-song-details/request-song-details.module').then( m => m.RequestSongDetailsPageModule)
  },
  {
    path: 'my-balance',
    loadChildren: () => import('./pages/my-balance/my-balance.module').then( m => m.MyBalancePageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./commonpages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  
  {
    path: 'terms-of-service',
    loadChildren: () => import('./commonpages/terms-of-service/terms-of-service.module').then( m => m.TermsOfServicePageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./commonpages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'preview-song',
    loadChildren: () => import('./pages/preview-song/preview-song.module').then( m => m.PreviewSongPageModule)
  },
  {
    path: 'my-songs',
    loadChildren: () => import('./pages/my-songs/my-songs.module').then( m => m.MySongsPageModule)
  },
  {
    path: 'youtube-channels',
    loadChildren: () => import('./pages/youtube-channels/youtube-channels.module').then( m => m.YoutubeChannelsPageModule)
  },
  {
    path: 'youtubesongs',
    loadChildren: () => import('./pages/youtubesongs/youtubesongs.module').then( m => m.YoutubesongsPageModule)
  },
  {
    path: 'confirm-youtube',
    loadChildren: () => import('./pages/confirm-youtube/confirm-youtube.module').then( m => m.ConfirmYoutubePageModule)
  },
  {
    path: 'youtube-preview',
    loadChildren: () => import('./pages/youtube-preview/youtube-preview.module').then( m => m.YoutubePreviewPageModule)
  },
  {
    path: 'card-list',
    loadChildren: () => import('./pages/card-list/card-list.module').then( m => m.CardListPageModule)
  },
  {
    path: 'billing',
    loadChildren: () => import('./pages/billing/billing.module').then( m => m.BillingPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
