import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrefService } from './services/common/pref.service';
import { StorageEngineService } from './services/common/storage-engine.service';
import { LocalStorageService } from './services/common/local-storage.service';
import { UtilsService } from './services/common/utils.service';
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { TokenInterceptorService } from './token-interceptor.service';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { InAppPurchase } from '@ionic-native/in-app-purchase/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Media, MediaObject } from '@awesome-cordova-plugins/media/ngx';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx'; 
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyD-4LQLkT3Efn8UW9Efw9yoyPLdojw9qUc",
  authDomain: "lullaby-389710.firebaseapp.com",
  projectId: "lullaby-389710",
  storageBucket: "lullaby-389710.appspot.com",
  messagingSenderId: "245693103656",
  appId: "1:245693103656:web:9e0404771ce4d432b6ff1d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,HttpClientModule, IonicModule.forRoot({
    mode:"ios"
  }), AppRoutingModule],
  providers: [
    SplashScreen,
    StatusBar,
    NativeStorage,
    CallNumber,
    StorageEngineService,
    LocalStorageService,
    UtilsService,
    File,
    PrefService,
    Device,
    FileTransfer,
    InAppPurchase,
    Diagnostic,
    Media,
    WebView,
    AndroidPermissions,
    FCM,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    //  {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true},
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {}
